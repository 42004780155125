import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Field, reduxForm} from 'redux-form';
import TextAreaField from '../../Form/Fields/TextAreaField';
import Button from '../../Common/Button';
import {
  CheckmarkIcon,
  EditIconGreen,
  RedCancelIcon,
} from '../../../constants/images';
import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {API_ADMIN_SALE_NOTE_REQUESTED} from '../../../constants/actionTypes';

let SaleNote = ({sale = {}, handleSubmit, intl, reset, dispatch, ...props}) => {
  const [editing, setEditing] = useState(false);

  const submit = values => {
    dispatch({
      type: API_ADMIN_SALE_NOTE_REQUESTED,
      payload: {values, id: sale.id},
    });
    setEditing(false);
  };

  return (
    <div className="card details-card sales-summary mx-0 mb-3">
      <div className="card-body pb-4">
        <form onSubmit={handleSubmit(submit)}>
          <Typography variant="h4" className="mb-3">
            {intl.formatMessage({id: 'actions.note'})}
          </Typography>
          {!editing ? (
            <div className="pb-2">
              <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                {sale.note}
              </Typography>
            </div>
          ) : (
            <Field name="note" component={TextAreaField} minRows={4} autosize />
          )}
          {!editing ? (
            <Button
              small
              link
              primary
              icon={<EditIconGreen />}
              title={
                sale.note
                  ? intl.formatMessage({id: 'actions.edit'})
                  : intl.formatMessage({id: 'actions.add'})
              }
              onClick={() => setEditing(true)}
            />
          ) : (
            <div className="row align-items-center pt-2 mt-1">
              <div className="col-auto">
                <Button
                  small
                  link
                  primary
                  icon={<CheckmarkIcon style={{fontSize: 13}} />}
                  title={intl.formatMessage({id: 'actions.save'})}
                  type="submit"
                />
              </div>
              <div className="col-auto">
                <Button
                  small
                  link
                  danger
                  icon={<RedCancelIcon style={{fontSize: 11}} />}
                  title={intl.formatMessage({id: 'actions.cancel'})}
                  onClick={() => setEditing(false)}
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

SaleNote.propTypes = {
  sale: PropTypes.object,
};

SaleNote = reduxForm({
  form: 'SaleNoteForm',
  enableReinitialize: true,
})(SaleNote);

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      note: ownProps.sale?.note,
    },
  };
};

export default connect(mapStateToProps)(injectIntl(SaleNote));
